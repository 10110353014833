import React, { useEffect } from 'react';
import { Filter, ReferenceInput, AutocompleteInput, DateInput, ListBase, Title, Pagination, ListToolbar, usePermissions, useGetList, useGetResourceLabel, TopToolbar } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { DefaultListActions } from '../common/views/List';
import IconCard from '../common/IconCard';
import StatusInput from './inputs/StatusInput';
import TypeInput from './inputs/TypeInput';
import LeaveRow from './LeaveRow';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';


// TODO: hide filter when not admin
const LeaveFilter = (props) => (
    <Filter {...props} variant="outlined">
        <ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'name', order: 'ASC' }} alwaysOn>
            <AutocompleteInput optionText={userOptionText} size="small" />
        </ReferenceInput>
        <TypeInput alwaysOn />
        <StatusInput source="status" alwaysOn />
        <DateInput source="start_date" alwaysOn />
        <DateInput source="end_date" alwaysOn />
    </Filter>
);

const AdminLeaveView = () => {
    const { data: users } = useGetList(
        'users',
        {
            pagination: { page: 1, perPage: 30 },
            sort: { field: 'id', order: 'DESC' }
        }
    );

    return (
        <Grid container spacing={2}>
            {users?.map(user => (
                <Grid item lg={3} sm={6} xl={3} xs={12} key={`user_leave_${user.id}`}>
                    <IconCard title={user.fullname} value="1/26" icon={null} />
                </Grid>
            ))}
        </Grid>
    );
};

const PersonalLeaveView = () => {
    useEffect(() => {
        // TODO: fetch and display personal data
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title="Total leave taken" value="9/26" icon={<EventBusyIcon />} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard title="Total remaining leave" value="18/26" icon={<EventAvailableIcon />} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                    <Typography color="text.secondary">Recuperation days: 2</Typography>
                    <Typography color="text.secondary">Sick leave days: 2</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

const CustomList = ({ children, actions, filters, title, ...props }) => {
    const { permissions } = usePermissions();
    const getResourceLabel = useGetResourceLabel();

    return (
        <Box my={3}>
            <ListBase {...props}>
                <Title title={getResourceLabel('leaves', 2)} />
                <Box mb={3}>
                    {permissions?.includes('is_admin') ? (
                        <AdminLeaveView />
                    ) : (
                        <PersonalLeaveView />
                    )}
                </Box>
                <ListToolbar filters={filters} actions={<TopToolbar><DefaultListActions /></TopToolbar>} />
                <Card>
                    {children}
                </Card>
                <Pagination />
            </ListBase>
        </Box>
    );
};

const LeaveList = props => (
    <CustomList {...props} filters={<LeaveFilter />}>
        <LeaveRow />
    </CustomList>
);

export default LeaveList;