import React from 'react';
import { List as RAList, Pagination, CreateButton, ExportButton, TopToolbar, useResourceContext, useResourceDefinition } from 'react-admin';

const DefaultListActions = ({ bulkActions, filterValues, selectedIds, onUnselectItems, filters, showFilter, displayedFilters, className, children, ...props }) => {
	const resource = useResourceContext();
	const { hasCreate } = useResourceDefinition({ resource });

	return (
		<>
			{bulkActions && React.cloneElement(bulkActions, {
				filterValues,
				resource,
				selectedIds,
				onUnselectItems,
			})}
			{children}
			{hasCreate && <CreateButton />}
			<ExportButton maxResults={5000} />
		</>
	);
};

const ListActions = ({ className, ...props }) => (
	<TopToolbar className={className}>
		<DefaultListActions {...props} />
	</TopToolbar>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const List = ({
	pagination = <ListPagination />,
	perPage = 25,
	sort = { field: 'id', order: 'DESC' },
	...props
}) => (
	<RAList actions={<ListActions />} pagination={pagination} perPage={perPage} sort={sort} {...props} />
);

export default List;
export { DefaultListActions, ListActions, ListPagination };