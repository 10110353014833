import React, { useState, useEffect } from 'react';
import { Create as RACreate, ListButton, useCreateContext, useResourceDefinition, useTranslate } from 'react-admin';

import CardActions from '../layout/CardActions';
import Breadcrumb from '../layout/Breadcrumb';


const CreateActions = ({ className, children, breadcrumb }) => {
	const { resource, record } = useCreateContext();
	const { hasList } = useResourceDefinition({ resource });
	const [icon, setIcon] = useState(null);
	const translate = useTranslate();

	useEffect(() => {
		async function getIcon() {
			try {
				if (resource) {
					const moduleResource = await import(`../../${resource}`);
					if (moduleResource?.default?.icon) {
						setIcon(<moduleResource.default.icon />);
					}
				}
			} catch (e) {
				console.error(e);
			}
		}

		if (resource) {
			getIcon();
		}
	}, [resource]);

	const breadcrumbPath = [
		{ url: `/${resource}`, title: translate(`resources.${resource}.name`, { smart_count: 2, _: resource }), isActive: false, icon },
		{
			url: record ? `/${resource}/${record.id}/create` : '',
			title: translate('ra.action.create'),
			isActive: true
		}
	];

	return (
		<CardActions className={className}>
			<Breadcrumb path={breadcrumb || breadcrumbPath} />
			<div>
				{children}
				{hasList && <ListButton />}
			</div>
		</CardActions>
	);
};


const Create = ({ actions = <CreateActions />, ...props }) => (
	<RACreate actions={actions} {...props} />
);

export default Create;
export { CreateActions };