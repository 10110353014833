import React from 'react';
import {
    List,
    Datagrid,
    TextField
} from 'react-admin';

const CustomerList = props => (
    <List {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
        </Datagrid>
    </List>
);
export default CustomerList;