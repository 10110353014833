import React from 'react';
import {
    SimpleForm,
    TextInput
} from 'react-admin';

import Create from '../common/views/Create';

const ProjectCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" options={{ multiline: true }} />
        </SimpleForm>
    </Create>
);

export default ProjectCreate;

