import UserIcon from '@mui/icons-material/People';
import { EditGuesser } from 'react-admin';

import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';


const customers = {
	list: CustomerList,
	edit: EditGuesser,
	create: CustomerCreate,
	icon: UserIcon
};

export default customers
