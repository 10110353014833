import React from 'react';
import { SimpleShowLayout, TextField, BooleanField } from 'react-admin';

import Show from '../common/views/Show';

const UserShow = props => (
	<Show {...props}>
		<SimpleShowLayout>
			<TextField source="id" />
			<BooleanField source="active" />
			<TextField source="email" />
			<TextField source="firstname" />
			<TextField source="lastname" />
			<BooleanField source="is_admin" label="Admin" />
			<BooleanField source="is_staff" label="Staff" />
		</SimpleShowLayout>
	</Show>
);

export default UserShow;