import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'pending', name: 'Pending' },
	{ id: 'accepted', name: 'Accepted' },
	{ id: 'refused', name: 'Refused' }
];

const StatusInput = ({ classes, record, source = 'status', ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default StatusInput;
