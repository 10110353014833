import React from 'react';
import { AutocompleteInput } from 'react-admin';

const choices = [
	{ id: 'leave', name: 'Leave' },
	{ id: 'recup', name: 'Recup' },
	{ id: 'other', name: 'Other' },
];

const TypeInput = ({ classes, record, source = 'type', ...props }) => {
	if (!record) {
		return null;
	}

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default TypeInput;
