import React from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';
import TypeInput from './inputs/TypeInput';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';

const LeaveEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText={userOptionText} size="small" />
            </ReferenceInput>
            <TypeInput />
            <DateInput source="start_date" />
            <DateInput source="end_date" />
            <NumberInput source="duration" label="Number of days" step={0.5} min={0.5} />
            <TextInput source="description" options={{ multiline: true }} rows="4" />
        </SimpleForm>
    </Edit>
);

export default LeaveEdit;