import React from 'react';
import { SimpleShowLayout, TextField, ReferenceField, TextInput, usePermissions, SimpleForm, useRecordContext, useTranslate, useNotify, useRefresh } from 'react-admin';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import { ShowBase, ShowActions } from '../common/views/Show';
import DateField from '../common/fields/DateField';
import MultilineTextField from '../common/fields/MultilineTextField';
import CustomList from '../common/views/CustomList';
import TypeField from './fields/TypeField';
import DurationField from './fields/DurationField';
import StatusField from './fields/StatusField';
import LeaveRow from './LeaveRow';

import { markAcceptedLeave, markRefusedLeave } from 'services/api';

const LeaveShowActions = (props) => (
    <ShowActions {...props} hasEdit={props?.data?.status === 'pending'} />
);

const LeaveForm = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();

	const handleAccept = async () => {
		const response = await markAcceptedLeave(record.id);

		if (response.success) {
			notify(`Leave accepted`);
			refresh();
		} else if(response.reason) {
			notify(response.reason, 'warning');
		}
	};

	const handleRefuse = async () => {
		const response = await markRefusedLeave(record.id);

		if (response.success) {
			notify(`Leave refused`);
			refresh();
		} else if(response.reason) {
			notify(response.reason, 'warning');
		}
	};

    return (
        <SimpleForm defaultValues={{ comments: record?.comments }} toolbar={false}>
            <TextInput source="comments" options={{ multiline: true }} multiline fullWidth rows="4" />
            <Grid container spacing={2}>
                <Grid item>
                    <Button variant="contained" color="success" onClick={handleAccept}>{translate('Accept')}</Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="error" onClick={handleRefuse}>{translate('Refuse')}</Button>
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

const LeaveShow = () => {
    const { permissions } = usePermissions();
    const translate = useTranslate();

    return (
        <ShowBase>
            <LeaveShowActions />
            <Grid container spacing={2} sx={{ marginBottom: '30px' }}>
                <Grid item xs={12}>
                    <Paper>
                        <SimpleShowLayout>
                            <ReferenceField label="User" source="user_id" reference="users">
                                <TextField source="fullname" />
                            </ReferenceField>
                            <TypeField />
                            <DateField source="start_date" />
                            <DateField source="end_date" />
                            <DurationField label="Number of days" />
                            <MultilineTextField source="description" />

                            {!permissions?.includes('is_admin') && <hr />}
                            {!permissions?.includes('is_admin') && <StatusField />}
                            {!permissions?.includes('is_admin') && <MultilineTextField source="comments" />}
                        </SimpleShowLayout>
                    </Paper>
                </Grid>
                {permissions?.includes('is_admin') && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={translate('Leave validation')} />
                            <CardContent>
                                <LeaveForm />
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                {permissions?.includes('is_admin') && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title={translate('Other leaves at the same period')} />
                            <CardContent>
                                {/* TODO: filter leave list */}
                                <CustomList
                                    resource="leaves"
                                    sort={{ field: 'id', order: 'DESC' }}
                                    basePath="/leaves"
                                    filters={null}
                                    bulkActionButtons={false}
                                    exporter={false}
                                    actions={false}
                                >
                                    <LeaveRow />
                                </CustomList>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </ShowBase >
    );
};

export default LeaveShow;