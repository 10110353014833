import React from 'react';
import { TextInput, BooleanInput, minLength } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';

const UserEdit = props => (
	<Edit {...props}>
		<SimpleForm>
			<TextInput source="id" disabled />
			<BooleanInput source="active" />
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<TextInput source="password" validate={[minLength(8)]} />
			<BooleanInput source="is_admin" label="Admin" />
			<BooleanInput source="is_staff" label="Staff" />
		</SimpleForm>
	</Edit>
);

export default UserEdit;