import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const styles = {
	popper: {
		zIndex: 999
	},
	menuItem: {
		width: 'auto',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		padding: 0,
		minHeight: 'auto',

		'& > a, & button': {
			padding: '6px 16px',
			width: '100%',
			justifyContent: 'flex-start'
		}
	},
	firstButton: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		borderRightColor: 'transparent',
		'& .MuiButton-startIcon': {
			marginRight: 0
		}
	},
	lastButton: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		marginLeft: '-1px'
	}
};

const hasParentClass = (element, classnames) => {
	if (
		(typeof element.className == 'string' && element.className && element.className.split(' ').some(elementClass => classnames.some(className => elementClass.includes(className))))
		|| element.getAttribute('role') === 'tooltip'
	) {
		return true;
	}
	return (element.parentNode && element.parentNode.tagName !== 'BODY') && hasParentClass(element.parentNode, classnames);
};

const SplitButton = ({ children, color = 'primary', size = 'small', ...props }) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);
	const filteredChildren = React.Children.toArray(children, child => child).filter(child => child);

	const handleClick = (e) => {
		e.stopPropagation();
	};

	const handleMenuItemClick = (e) => {
		handleClick(e);

		// FIXME disabled for modal
		// 	setOpen(false);
	};

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = e => {
		// TODO: maybe find another solution later
		if ((anchorRef.current && anchorRef.current.contains(e.target)) || hasParentClass(e.target, ['MuiDialog', 'RaAutocompleteInput'])) {
			e.stopPropagation();
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<ButtonGroup variant="outlined" size={size} ref={anchorRef} aria-label="split button" {...props} onClick={handleClick} color={color}>
				{React.cloneElement(filteredChildren[0], {
					label: "",
					sx: styles.firstButton
				})}
				<Button
					size={size}
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-haspopup="menu"
					onClick={handleToggle}
					sx={styles.lastButton}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal sx={styles.popper}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<List id="split-button-menu">
									{React.Children.map(filteredChildren, (child) => (
										<ListItem onClick={handleMenuItemClick} sx={styles.menuItem}>{child}</ListItem>
									))}
								</List>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

SplitButton.propTypes = {
	color: PropTypes.string,
	size: PropTypes.string
};

export default SplitButton;