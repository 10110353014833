import { API_URL } from './settings';

const options = {
	headers: new Headers({
		'Content-Type': 'application/json',
		'X-Authorization': `Bearer ${localStorage.getItem('token')}`
	})
};

export const search = async (terms) => {
	const response = await fetch(`${API_URL}/search/live?q=${terms}`);
	const { results } =  await response.json();
	return results;
}

export const fetchAdminDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/stats`);
	return await response.json();
}

/* Account */
export const getAccount = async () => {
	const response = await fetch(`${API_URL}/account`, options);
	return await response.json();
};

export const updateAccount = async (data) => {
	const response = await fetch(`${API_URL}/account`, { ...options, method: 'PUT', body: data });
	return await response.json();
};

export const forgotPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/forget_password`, { method: 'POST', body: data });
	return await response.json();
};

export const resetPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/reset_password`, { method: 'POST', body: data });
	return await response.json();
};

/* Leave */
export const markAcceptedLeave = async (leaveId) => {
	const response = await fetch(`${API_URL}/leaves/${leaveId}/mark_accepted`, { method: 'POST' });
	return await response.json();
}

export const markRefusedLeave = async (leaveId) => {
	const response = await fetch(`${API_URL}/leaves/${leaveId}/mark_refused`, { method: 'POST' });
	return await response.json();
}
