import React from 'react';
import { TextInput, DateInput, NumberInput, ReferenceInput, AutocompleteInput, usePermissions } from 'react-admin';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';
import TypeInput from './inputs/TypeInput';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';

const LeaveCreate = (props) => {
    const { permissions } = usePermissions();

    return (
        <Create {...props}>
            <SimpleForm>
                {permissions?.includes('is_admin') && (
                    <ReferenceInput source="user_id" reference="users" sort={{ field: 'name', order: 'ASC' }}>
                        <AutocompleteInput optionText={userOptionText} size="small" />
                    </ReferenceInput>
                )}
                <TypeInput />
                <DateInput source="start_date" />
                <DateInput source="end_date" />
                <NumberInput source="duration" label="Number of days" step={0.5} min={0.5} />
                <TextInput source="description" options={{ multiline: true }} multiline fullWidth rows="4" />
            </SimpleForm>
        </Create>
    );
};

export default LeaveCreate;