import React, { useEffect, useState } from 'react';
import { Title, TextInput, PasswordInput, useNotify, useTranslate, required, minLength, useAuthState, useAuthenticated } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import SimpleForm from '../common/forms/SimpleForm';

import { getAccount, updateAccount } from '../../services/api';
import { changeTheme } from '../../actions/theme';


const Account = () => {
    const [account, setAccount] = useState({});
    const theme = useSelector((state) => state.theme);
    const dispatch = useDispatch();
    const notify = useNotify();
    const translate = useTranslate();
    const { isLoading, authenticated } = useAuthState();
    useAuthenticated();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getAccount();
                setAccount(response);
            } catch (error) {
                console.error(error);
            }
        };

        if (!isLoading && authenticated) {
            fetchData();
        }
    }, [isLoading, authenticated]);

    const handleChangeTheme = () => {
        dispatch(changeTheme(theme === 'light' ? 'dark' : 'light'));
    };

    const handleSubmit = async (data) => {
        const response = await updateAccount(JSON.stringify(data));
        if (response.errors) {
            let message = '';
            for (const error in response.errors) {
                message += `${error}: ${response.errors[error]}\n`;
            }
            notify(message, "warning");
        }
        else {
            notify("Account updated");
        }
    };

    return (
        <Box my={3}>
            <Card>
                <Title title="Account" />
                <SimpleForm record={account} onSubmit={handleSubmit} resource="account">
                    <><Typography variant="h5" gutterBottom>{translate('Account')}</Typography></>
                    <TextInput source="firstname" validate={required()} />
                    <TextInput source="lastname" validate={required()} />
                    <PasswordInput source="password" validate={minLength(8)} />
                    <>
                        <FormControl>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Switch color="primary" onChange={handleChangeTheme} checked={theme === 'dark'} />}
                                    label="Dark theme"
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </FormControl>
                    </>
                </SimpleForm>
            </Card>
        </Box>
    );
};

export default Account;