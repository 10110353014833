import React, { useState, useEffect } from 'react';
import { Title, Link, useTranslate } from 'react-admin';
import Grid from '@mui/material/Grid';
import ProjectIcon from '@mui/icons-material/List';
import DescriptionIcon from '@mui/icons-material/Description';
import UserIcon from '@mui/icons-material/People';

import IconCard from './common/IconCard';
import { fetchAdminDashboardStats } from '../services/api';


const Dashboard = () => {
    const [stats, setStats] = useState();
    const translate = useTranslate();

    useEffect(() => {
        const fetchStats = async () => {
            const data = await fetchAdminDashboardStats();
            setStats(data);
        }

        fetchStats();
    }, []);

    return (
        <>
            <Title title="Kernel" />
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/invoices">
                        <IconCard
                            title={translate('resources.invoices.name', { smart_count: 2, _: "Invoices" })}
                            value={stats?.invoices}
                            icon={<DescriptionIcon />}
                        />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/customers">
                        <IconCard
                            title={translate('resources.customers.name', { smart_count: 2, _: "Customers" })}
                            value={stats?.customers}
                            icon={<ProjectIcon />}
                        />
                    </Link>
                </Grid>
                <Grid item lg={3} sm={6} xl={3} xs={12}>
                    <Link to="/users">
                        <IconCard
                            title={translate('resources.users.name', { smart_count: 2, _: "Users" })}
                            value={stats?.users}
                            icon={<UserIcon />}
                        />
                    </Link>
                </Grid>
            </Grid>
        </>
    );
}

export default Dashboard;