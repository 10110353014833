const translations = {
    resources: {
        invoices: {
            name: 'Facture |||| Factures'
        },
        leaves: {
            name: 'Congé |||| Congés',
            fields: {
                start_date: 'Date de début',
                end_date: 'Date de fin',
                duration: 'Durée',
                status: 'Statut',
                comments: 'Commentaires',
                user_id: 'Utilisateur'
            }
        },
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                active: 'Actif',
                email: 'E-mail',
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe'
            }
        },
        account: {
            fields: {
                firstname: 'Prénom',
                lastname: 'Nom',
                password: 'Mot de passe'
            }
        }
    },
    'User': 'Utilisateur',
    'Name': 'Nom',
    'Number of days': 'Nombre de jours',
    'Search': 'Recherche',
    'Account': 'Compte',
    'Account updated': 'Compte mis à jour',
    'Leave validation': 'Validation du congé',
    'Other leaves at the same period': 'Autres congés à la même période',
    'Pending': 'En attente',
    'Accepted': 'Accepté',
    'Refused': 'Refusé',
    'Leave': 'Congé',
    'Recup': 'Récup',
    'Other': 'Autre'
};

export default translations;
