import React from 'react';
import { TextInput, BooleanInput, required, minLength } from 'react-admin';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';

const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<BooleanInput source="active" defaultValue={true} />
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<TextInput source="password" validate={[required(), minLength(8)]} />
			<BooleanInput source="is_admin" label="Admin" />
			<BooleanInput source="is_staff" label="Staff" />
		</SimpleForm>
	</Create>
);

export default UserCreate;