import React from 'react';
import { Link, useRecordContext, useCreatePath } from 'react-admin';


export const ResourceLinkField = ({ source = 'id', type = 'show', ...props }) => {
	const record = useRecordContext();
	const createPath = useCreatePath();
    const { children, resource } = props;

	return (
		<Link to={createPath({ resource, type, id: record?.id })}>
			{React.cloneElement(children, { record })}
		</Link>
	);
};

export default ResourceLinkField;