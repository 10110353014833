import { createTheme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';

import variables from './variables';

const theme = {
	...defaultTheme,
	components: {
		...defaultTheme.components,
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: '0px 1px 30px 1px rgba(0, 0, 0, 0.11)',
					zIndex: 1300
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				docked: {
					marginRight: '19px'
				}
			}
		},
		MuiFormControl: {
			defaultProps: {
				variant: 'outlined',
				size: 'small'
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				size: 'small'
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				message: {
					whiteSpace: 'pre-wrap'
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'@media (min-width: 600px)': {
						minHeight: '36px'
					}
				}
			}
		},
		MuiInputBase: {
			defaultProps: {
				autoComplete: 'off'
			}
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					borderBottom: '1px solid #DADCE0',
				},
				title: {
					fontSize: '22px'
				}
			}
		},
		RaRichTextInput: {
			styleOverrides: {
				root: {
					maxWidth: '100%',
					'& .RaRichTextInput-editorContent img': {
						maxWidth: '100%'
					},
					'& .RaRichTextInputToolbar-root': {
						alignSelf: 'flex-start'
					}
				}
			}
		}
	}
};

const lightTheme = createTheme({
	palette: {
		...theme.palette,
		primary: {
			main: variables.colors.primary
		}
	},
	shadows: [
		"none",
		"0 1px 15px 1px rgba(0, 0, 0, 0.11)",
		"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
		"0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
		"0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
		"0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
		"0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
		"0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
		"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
		"0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
		"0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
		"0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
		"0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
		"0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
		"0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
		"0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
		"0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
		"0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
		"0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
		"0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
		"0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
		"0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
		"0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
		"0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
		"0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)"
	]
}, theme);

const defaultDarkTheme = createTheme({ palette: { mode: 'dark' } });
const darkTheme = createTheme({
	...theme,
	palette: {
		mode: 'dark',
		primary: {
			main: variables.colors.lightPrimary
		},
		background: {
			paper: '#262626'
		}
	},
	components: {
		...theme.components,
		MuiFormGroup: {
			styleOverrides: {
				root: {
					color: defaultDarkTheme.palette.text.primary
				}
			}
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					'& ::-webkit-calendar-picker-indicator': {
						filter: 'invert(1)'
					}
				}
			}
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					borderBottom: `1px solid ${defaultDarkTheme.palette.background.default}`,
				},
				title: {
					fontSize: '22px'
				}
			}
		}
	}
});

export { lightTheme, darkTheme, theme };
