// in src/App.js
import React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router';
import { configureStore } from '@reduxjs/toolkit';
import { Provider, useSelector } from 'react-redux';

import Dashboard from './components/Dashboard';
import AppLayout from './components/AppLayout';
import LoginPage from './components/account/LoginPage';
import Account from './components/account/Account';
import ForgotPassword from './components/account/ForgotPassword';
import ResetPassword from './components/account/ResetPassword';

import users from './components/users';
import customers from './components/customers';
import leaves from './components/leaves';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import themeReducer from './reducers/theme';

import i18nProvider from './i18n/i18nProvider';
import { lightTheme, darkTheme } from './style/themes';

const CustomAdmin = () => {
  const theme = useSelector((state) =>
    state.theme === 'dark' ? darkTheme : lightTheme
  );

  return (
    <Admin
      layout={AppLayout}
      dashboard={Dashboard}
      loginPage={LoginPage}
      dataProvider={dataProvider}
      authProvider={authProvider}
      title="Kernel"
      disableTelemetry
      theme={theme}
      i18nProvider={i18nProvider}
    >
      <Resource name="users" {...users} />
      <Resource name="customers" {...customers} />
      <Resource name="leaves" {...leaves} />
      <CustomRoutes>
        <Route path="/account" element={<Account />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
      </CustomRoutes>
    </Admin>
  );
};

const App = () => (
  <Provider store={configureStore({ reducer: { theme: themeReducer } })}>
    <CustomAdmin />
  </Provider>
);

export default App;
