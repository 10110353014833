import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import orange from '@mui/material/colors/orange';

import ListIcon from '@mui/icons-material/List';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		boxShadow: '0px 1px 30px 1px rgba(0,0,0,0.11)',
		display: 'inline-block',
		width: '100%',
		textDecoration: 'none'
	},
	content: {
		alignItems: 'center',
		display: 'flex'
	},
	title: {
		color: theme.palette.text.secondary,
		fontWeight: 700
	},
	value: {
		marginTop: theme.spacing(),
		color: props => props.warning ? orange[500] : theme.palette.text.primary,
	},
	iconWrapper: {
		alignItems: 'center',
		backgroundColor: theme.palette.primary.main,
		borderRadius: '50%',
		display: 'inline-flex',
		height: '4rem',
		justifyContent: 'center',
		marginLeft: 'auto',
		width: '4rem',
		'& svg': {
			color: theme.palette.common.white,
			fontSize: '2rem',
			height: '2rem',
			width: '2rem'
		}
	},
	icon: {
		color: theme.palette.common.white,
		fontSize: '2rem',
		height: '2rem',
		width: '2rem'
	}
}));

const IconCard = ({ title, value, icon = <ListIcon /> }) => {
	const classes = useStyles();

	return (
		<Paper className={classes.root}>
			<div className={classes.content}>
				<div className={classes.details}>
					<Typography className={classes.title} variant="body1">{title}</Typography>
					<Typography className={classes.value} variant="h4">{value}</Typography>
				</div>
				{icon && (
					<div className={classes.iconWrapper}>
						{icon}
					</div>
				)}
			</div>
		</Paper>
	);
};

IconCard.propTypes = {
	icon: PropTypes.element,
	title: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default IconCard;
