import React from 'react';
import { ShowButton, EditButton, DeleteButton } from 'react-admin';

import SplitButton from './SplitButton';

const RecordSplitButton = ({ record, children, hasEdit = true, hasShow = true, deleteButton, ...props }) => (
	<SplitButton>
		{hasShow && <ShowButton record={record} />}
		{hasEdit && (<EditButton record={record} />)}
		{React.Children.map(children, (child => child && React.cloneElement(child, { record, ...props }) ))}
		{deleteButton ? React.cloneElement(deleteButton, { record, ...props }) : <DeleteButton record={record} mutationMode="pessimistic" />}
	</SplitButton>
);

export default RecordSplitButton;