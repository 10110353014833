import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import { Layout } from 'react-admin';
import Menu from './Menu';
import AppBar from './common/layout/AppBar';
import Sidebar from './common/layout/Sidebar';

const AppLayout = props => (
    <Layout
        {...props}
        appBar={AppBar}
        menu={Menu}
        sidebar={Sidebar}
    >
        <CssBaseline />
        {props.children}
    </Layout>
);

export default AppLayout;
