import ProjectIcon from '@mui/icons-material/List';
import { EditGuesser } from 'react-admin';

import ProjectList from './ProjectList';
import ProjectCreate from './ProjectCreate';


const projects = {
	list: ProjectList,
	edit: EditGuesser,
	create: ProjectCreate,
	icon: ProjectIcon
};

export default projects;
