import React from 'react';
import { TextInput } from 'react-admin';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';

const CustomerCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="short_name" />
            <TextInput source="description" options={{ multiline: true }} />
        </SimpleForm>
    </Create>
);

export default CustomerCreate;
