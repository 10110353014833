import React from 'react';
import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';

const types = {
	'': '',
};

const TypeField = ({ source = 'type' }) => {
	const record = useRecordContext();

	if(!record) {
		return null;
	}

	const key = record ? record[source] : null;

	return (
		<Typography variant="body2">{types[key]}</Typography>
	);
};

export default TypeField;
