import React from 'react';
import { Link } from 'react-admin';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
	ul: {
		display: 'flex',
		alignItems: 'stretch',
		padding: 0,
		paddingBottom: '5px'
	},
	li: {
		listStyle: 'none',

		display: 'flex',
		alignItems: 'flex-end',

		'& a, & p': {
			lineHeight: '20px'
		},
		'& a': {
			display: 'flex',
			alignItems: 'flex-end',

			'& svg': {
				paddingRight: '5px'
			}
		},
		
		'&:not(:last-child)::after': {
			content: '"/"',
			padding: '0 5px',
			color: theme.palette.text.primary,
			lineHeight: '20px'
		}
	},
	text: {
		display: 'inline',
		fontSize: '14px'
	}
}));

const capitalize = (str) => {
	if(!str) {
		return;
	}
	str = str.toString();
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const Breadcrumb = ({ path }) => {
	const classes = useStyles();

	return (
		<ul className={classes.ul}>
			{path.map((item) => (
				<li className={classes.li} key={`breadcrumb_${item.title}`}>
					{!item.isActive ? (
						<Link to={item.url} className={classes.text}>{item.icon} {capitalize(item.title)}</Link>
					) : (
						<Typography className={classes.text} color="textPrimary">{capitalize(item.title)}</Typography>
					)}
				</li>
			))}
		</ul>
	);
};

export default Breadcrumb;